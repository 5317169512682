import { useMemo } from 'react';

import { RMInput } from '../RMInput';

export interface RMDateInputProps {
  id: string;
  type: 'date' | 'datetime';
  label?: string;
  placeholder?: string;
  defaultTime?: string;
  value?: string | null;
  error?: string | null;
  min?: string;
  onChange?: (value: string | null) => void;
  onBlur?: () => void;
}

export function RMDateInput({ type, defaultTime = '00:00:00', value, onChange, onBlur, ...props }: RMDateInputProps) {
  const parsedValue = useMemo(() => {
    if (value == null) {
      return '';
    }
    if (type === 'date' && value.includes('T')) {
      return value.split('T')[0];
    }
    if (type === 'datetime' && !value.includes('T')) {
      return `${value}T${defaultTime}`;
    }
    return value;
  }, [value, type, defaultTime]);

  return (
    <RMInput
      {...props}
      type={type === 'datetime' ? 'datetime-local' : type}
      value={parsedValue}
      onChange={(e) => {
        const newValue = e.target.value;
        if (newValue.includes('T')) {
          onChange?.(newValue);
          onBlur?.();
          return;
        }
        onChange?.(`${newValue}T${defaultTime}`);
        onBlur?.();
      }}
      onBlur={onBlur}
    />
  );
}
